// import React, { useEffect, useState } from "react";
// import img from "./cerbg.svg"; // Ensure this path is correct

// const GetCertification: React.FC = () => {
//   const [username, setUsername] = useState<string>("");
//   const [courseTitle, setCourseTitle] = useState<string>("");

//   useEffect(() => {
//     // Extract username and course title from URL parameters
//     const params = new URLSearchParams(window.location.search);
//     const usernameFromParams = params.get("username");
//     const courseTitleFromParams = params.get("certSlug");

//     if (usernameFromParams) {
//       setUsername(decodeURIComponent(usernameFromParams)); // Decode URL-encoded username
//     }
//     if (courseTitleFromParams) {
//       setCourseTitle(decodeURIComponent(courseTitleFromParams)); // Decode URL-encoded course title
//     }
//   }, []);

//   return (
//     <>
//       <div className="container certification-wrapper-new">
//         <section
//           className="certification-container"
//           style={{ backgroundImage: `url(${img})` }}
//         >
//           <div className="certification-header">
//             <img
//               className="logo"
//               src="https://w4j.yool.education/assets/web4jobs/images/logo-light.png"
//               alt="Web4Jobs Logo"
//             />
//           </div>
//           <div className="certification-content">
//             <p>This certifies that </p>
//             <h2 className="recipient-name">{username}</h2>
//             <p>Successfully completed</p>
//             <h3 className="course-name">{courseTitle}</h3>

//             <p>
//               Certified by{" "}
//               <strong className="web4jobs-bolder">Web4Jobs </strong> on October
//               25, 2024 , representing approximately 300 hours of work
//             </p>

//             <p></p>
//             <p className="completion-date"></p>
//           </div>
//           <div className="certification-footer">
//             <div className="validation-code">
//               <img
//                 className="badge-new"
//                 src="https://icons.veryicon.com/png/o/miscellaneous/xdh-font-graphics-library/certification-badge.png"
//                 alt="Certification Badge"
//               />
//             </div>
//             <div className="signature">
//               <p>Authorized Signature</p>
//               <img
//                 className="signature-img"
//                 src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/Todd_Strasser_signature.png/1012px-Todd_Strasser_signature.png"
//                 alt="Signature"
//               />
//               <p className="issuer-name">web4jobs admin</p>
//             </div>
//             <p>
//               Validation Code:{" "} <br />
//               <img
//                 className="qr-code-cert"
//                 src="https://seeklogo.com/images/Q/qr-code-logo-27ADB92152-seeklogo.com.png"
//                 alt="QR Code"
//               />
//             </p>
//           </div>
//           <div className="cop">
//            <span>Verify this certification at:</span> <br />
//            <span>https://pre-cert.web4jobs.ma/get-certification?username={username}&certSlug={courseTitle}</span>
//           </div>
//         </section>
//       </div>

//       <style>
//         {`
//           @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lexend:wght@100..900&display=swap');
//           @import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lexend:wght@100..900&display=swap');
          
//           .certification-wrapper-new {
//             min-height: 100vh;
//             font-family: "Lato", sans-serif;
//             padding-bottom:100px;
//           }

//           .certification-header {
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             background: #0a0a23;
//             color: white;
//             font-size: 32px;
//             padding: 14px;
//           }

//           .certification-header .logo {
//             width: 130px;
//             height: auto;
//           }

//           .badge-new {
//             width: 200px;
//             height: auto;
//           }

//           .certification-container {
//             max-width: 1400px;
//             margin: 0 auto;
//             padding: 10px;
//             min-height: 80vh;
//             border: 30px double black;
//             box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//             text-align: center;
//           }

//           .certification-container .web4jobs-bolder {
//             font-weight: 600;
//             font-size: 27px;
//             color: #0a0a23;
//           }

//           .certification-content {
//             padding-block: 44px;
//           }

//           .certification-content p {
//             font-size: 24px;
//             font-weight: 500;
//           }

//           .signature-img {
//             width: 250px;
//             height: auto;
//             margin-top: 10px;
//           }

//           .recipient-name {
//             font-size: 65px;
//             font-weight: 800;
//             color: #0a0a23;
//           }

//           .course-name {
//             font-size: 36px;
//             font-weight: 800;
//             color: #0a0a23;
//           }

//           .certification-footer {
//             display: flex;
//             justify-content: space-between;
//             align-items: center;
//             margin-top: 20px;
//             padding-inline: 50px;
//           }

//           .issuer-name {
//             font-weight: 400;
//             color: #333;
//             font-family: "Caveat", cursive;
//           }

//           .validation-code {
//             font-size: 0.9em;
//             color: #333;
//           }

//           .qr-code-cert {
//             width: 170px;
//           }
//          .cop{
//          text-align:center;
//          font-size:14px;
//          }
//         `}
//       </style>
//     </>
//   );
// };

// export default GetCertification;

import { Router } from '@reach/router';
import { withPrefix } from 'gatsby';
import React from 'react';

import ShowCertification from '../client-only-routes/show-certification';
import RedirectHome from '../components/redirect-home';

import './certification.css';

function GetCertification(): JSX.Element {
  return (
    <Router>
      <ShowCertification
        // Error from installing @types/react-helmet and @types/react-redux
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        path={withPrefix('/get-certification/:username/:certSlug')}

      />

      {/* <RedirectHome default /> */}
    </Router>
  );
}

export default GetCertification;
